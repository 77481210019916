<template>
    <div :class="$style.box">
		<div class="h-70 l-h-70 flex start c-3 f-24 font-fm-ruizi"><i class="shu t-25 relative m-r-10"></i>{{formData.title}}</div>
		<div class="">
			<div class="flex m-b-15">
				<a :href="item.imgLink" v-for="(item, index) in formData.imgList">
					<img  :key="index" class="flex-1 h-160" :class="index!=2?'m-r-20':''" :src="item.imgUrl" alt="">
				</a>
				
			</div>
			<div class="p-b-15" v-if="isNoEmpty">
				<div class="b-b-dcd flex">
					<div @click="bannerbtn(item)" class="w-192 h-80 b-b m-a tc  " :class="index!=5?'b-r-dcd':''" v-for="(item,index) in  brandlist[0]">
						<img class="h-50 w-120 m-t-15"  :src="item.imageurl" alt="">
					</div>
				</div>
				<div class="flex" >
					<div @click="bannerbtn(item)" class="w-192 h-80 b-b m-a tc " :class="index!=5?'b-r-dcd':''" v-for="(item,index) in  brandlist[1]">
						<img class="h-50 w-120 m-t-15"  :src="item.imageurl" alt="">
					</div>
					<!-- <div class="w-192 h-80 b-b m-a tc b-r-dcd l-h-80 f-14 flex s-c cursor" >
						<i class="b2b-huanyipi iconfont f-24 m-r-8"></i> 换一批
					</div> -->
				</div>
			</div>
		</div>
    </div>
</template>

<script>
export default {
    name: 'brand',
	data() {
		return{
			brandlist:[]
		}
	},
    props: {
        formData: {
            type: Object,
            default: () => ({})
        }
    },
	watch:{
		formData:function(e){
			this.formData=e
			console.log('brandformData',e)
		}
	},
	computed: {
		isNoEmpty() {
			const {formData} = this;
			console.log('brandformData',formData)
			this.getbrand()
			return !!(formData.imgList && formData.imgList.length > 0 && formData.imgList[0].imgUrl);
		}
	},
	activated() {
		this.getbrand()
	},
	methods:{
		getbrand(query) {
			let list=[]
			this.gethotbrands().then(res=>{
				// res=JSON.parse(JSON.stringify(res))
				list[0]=res.slice(0,6)
				list[1]=res.slice(6,12)
				console.log('brandres',this.brandlist=list)
			})
			
		},
		
		// 点击品牌跳转列表
		bannerbtn: function(item) {
			// window.location.href = '/view/goods/goodslist.html?brandname=' + item.brandname + "&brandid=" + item.id;
			if(location.href.indexOf('edit')==-1){
				
				if(!this.islogin())return
				document.documentElement.scrollTop=0
				console.log(item.brandname)
				
				// this.$router.push({path:'/goodslist',query:{ child,goodsid:item.categoryno,parentid:item.parentid,categoryname:item.categoryname,id:item.id}})
				this.$router.push({path:'/goodslist',query:{child:2,brandname:item.brandname,brandid:item.id}})
			}
		},
		
		
	}
	
};
</script>
<style>
	h2 {
	    text-align: center;
	    font-size: 38px;
	    padding: 30px 10px;
	    font-weight: bold;
	}
</style>
<style module>
    .box {
		background: #fff;
        min-height: 280px;
		margin: 10px 0;
		border-radius: 2px;
		padding: 0 20px;
    }
	.banneritem :nth-child(1){border-right:1px solid #DCDFE6 }
</style>
